import React, { Component } from 'react';
import { ZougglaScan } from '@zouggla/zouggla-react-sdk';
//import { MyModal } from "../lib/mymodal.js";
import '../App.css';
import { Redirect } from 'react-router';
import autoBind from 'react-autobind';

class Home extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoggedIn: false,
      q1: true,
      //      useProdStage: true,
      apiKey: 'vJyjum9yKr1m0tG62fToC1Ac13Yzn2Wu6Y1Ht4T6',
      stageLevel: 'prod'
    };
  }
  handleReturn = arg => {
    this.setState({ isLoggedIn: true });
  };
  whenScanned() {
    console.log('scanned it');
    this.setState({ q1: false });
  }
  render() {
    if (this.state.isLoggedIn) {
      return (
        <Redirect
          push
          to={{
            pathname: '/welcome',
            state: { jwttoken: '' }
          }}
        />
      );
    }

    const loginpayload = {
      partner: 'playgroundresearch',
      requestedFields: ['email']
    };

    return (
      <div className="App">
        <header className="App-header">
          <p>playgroundresearch.com: a meetings of the minds</p>
          <ZougglaScan
            toshow={this.state.q1}
            type="loginv2"
            data={loginpayload}
            howtoreturn={this.handleReturn}
            apiKey={this.state.apiKey}
            stageLevel={this.state.stageLevel}
          />
          <div className="Znote">
            Sign in with <a href="http://www.zouggla.com">Zouggla</a>
          </div>
        </header>

        <div />
      </div>
    );
  }
}

export default Home;
